import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
//import Headings from "../components/headings"
import Hero from "../components/hero"
import ContactForm from "../components/contact-form"
import Headings from "../components/headings"

import HomeIcon from "../icons/home-icon-silhouette.svg"
//import EmailIcon from "../icons/emails-icon.svg"
import PhoneIcon from "../icons/phone-icon.svg"
//import DonationsIcon from "../icons/donation-icon.svg"
import CharityIcon from "../icons/charity-icon-v1.svg"
import DonationsIconV2 from "../icons/donation-icon-v2.svg"
import CtaSlider from "../components/cta-slider"
import { Helmet } from "react-helmet"

const ContactUsPage = pageContext => {
  //console.log("Contact us - pageContext: ", pageContext)

  const { strapiContactUs } = useStaticQuery(graphql`
    query {
      strapiContactUs {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
          structuredData {
            _type
            name
            url
            contactPoint {
              _type
              availableLanguage
              contactType
              telephone
            }
            sameAs
          }
        }
        callToAction {
          ...StrapiCallToAction
        }
      }
    }
  `)
  const { hero, seo, callToAction } = strapiContactUs
  //console.log("strapiContactUs: ", strapiContactUs)

  const structuredData = seo.structuredData
  //console.log("strapiContactUs SEO: ", strapiContactUs.seo)

  //const callToAction = strapiContactUs.callToAction
  //console.log("strapiContactUs callToAction: ", callToAction)

  const callToActionSections =
    strapiContactUs.callToAction.call_to_action_sections
  //console.log("strapiContactUs callToActionSections: ", callToActionSections)

  const imageUrl = `${strapiContactUs.seo.metaImage.localFile.piblicURL}`

  /* console.log(
   "imageUrl: ",
   `${process.env.SITE_URL}${strapiContactUs.seo.metaImage.localFile.publicURL}`
 ) */

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactUsPage",
            structuredData,
          })}
        </script>
      </Helmet>
      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={imageUrl}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />
      <Hero hero={hero} />

      <section className="uk-section-default uk-padding-remove">
        <div
          className="uk-section uk-section-default uk-padding-remove"
          uk-height-viewport="expand: true"
        >
          <div className="uk-container" style={{ marginTop: "10px"}}>
            <div className="uk-grid-large" uk-grid="true" style={{ marginBottom: "20px"}}>
              <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
                <div className="uk-panel">
                  <Headings
                    title="Get in Touch Today"
                    description="If you need more information about any of our services, do not hesitate to contact us, using our form, or feel free to email us, by clicking on your icon of interest below, and we will get back to you"
                  />

                  <div className="uk-width-xlarge">
                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin uk-margin-medium-bottom">
                      <div className=" uk-flex-first uk-margin-medium-right">
                        <a href="mailto:support@jwr-ltd.co.uk?subject=James Walter Raymond Ltd - Info & Client support">
                          <HomeIcon
                            className="icon-color grow"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </a>
                      </div>
                      <div className="uk-flex uk-flex-column uk-margin-small-right ">
                        <div
                          className="uk-text-bold uk-margin-small-right"
                          style={{ color: "#687169" }}
                        >
                          Fot information or client support
                        </div>
                      </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-medium-bottom">
                      <div className=" uk-flex-first uk-margin-medium-right">
                        <a href="mailto:admin@jwr-ltd.co.uk?subject=James Walter Raymond Ltd - Facilities Management Service">
                          <DonationsIconV2
                            className="icon-color grow"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </a>
                      </div>
                      <div className="uk-flex uk-flex-column">
                        <div
                          className="uk-text-bold"
                          style={{ color: "#687169" }}
                        >
                          For facilities management service queries
                        </div>
                      </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-medium-bottom">
                      <div className=" uk-flex-first uk-margin-medium-right">
                        <a href="mailto:admin@jwr-ltd.co.uk?subject=James Walter Raymond Ltd - Client Propertry Service">
                          <CharityIcon
                            className="icon-color grow"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </a>
                      </div>
                      <div className="uk-flex uk-flex-column">
                        <div
                          className="uk-text-bold"
                          style={{ color: "#687169" }}
                        >
                          For client property service queries
                        </div>
                      </div>
                    </div>

                    <div className="uk-width-xlarge uk-flex uk-flex-middle uk-margin-small-bottom">
                      <div className=" uk-flex-first uk-margin-medium-right">
                        <a href="tel:02039165815">
                          <PhoneIcon
                            className="icon-color grow"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </a>
                      </div>
                      <div className="uk-flex uk-flex-column">
                        <div
                          className="uk-text-bold"
                          style={{ color: "#687169" }}
                        >
                          Give us a call today
                        </div>
                        <span
                          className="uk-text-bold"
                          style={{ color: "#687169" }}
                        >
                          0203 916 5815
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
                <div className="uk-panel">
                  <div className="uk-margin">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-muted uk-padding-remove">
          <div className="uk-container uk-margin-medium-bottom">
            <div style={{ marginBottom: "20px"}}>
              <h3 className="uk-margin-remove-bottom">
                Our Location
              </h3>
              <div style={{ marginTop: "20px"}}>
                <h5 className="uk-margin-remove">
                  James Walter Raymond Ltd Registered Office
                </h5>
              </div>              
            </div>

            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2464.1808517172653!2d-4.313990223019365!3d51.85765428505545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486ee7d652825eb5%3A0xdb6ed4a93249920f!2sH%20Jenkins%20%26%20Co!5e0!3m2!1sen!2suk!4v1706720591329!5m2!1sen!2suk" 
              width="800" 
              height="600"
              title="Our Registered Office LOcation"
              style={{border: "100px"}}
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
            >
            </iframe>
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUsPage
